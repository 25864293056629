<template>
  <div class="about-page">
    <div class="safe-area-banner">
      <div class="fs32">关于我们</div>
      <div class="fs16 desc">峨眉山行愿慈善事业基金会</div>
    </div>
    <div class="content-wrapper space-between">
      <div class="left-wrapper">
        <div class="dividing-line">
          <div class="title fs18">关于我们</div>
        </div>
        <div class="tab-wrapper">
          <div
            v-for="(tab, index) in aboutOptions"
            :key="tab.label"
            class="tab-item flex-center transition-600"
            :class="{'is-active': index === currentTab}"
            @click="handleChangeTab(index)"
          >
            {{ tab.label }}
          </div>
        </div>
      </div>

      <div v-if="aboutOptions.length > 0" class="right-wrapper">
        <div class="title fs18">{{ aboutOptions[currentTab].label }}</div>
        <div class="content" v-html="aboutOptions[currentTab].content"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { routeNames } from "@/constants/nav";
  import { emeiApi } from "@/service/api/emei";

  export default {
    name: "About",
    components: {},
    data() {
      return {
        routeNames,
        aboutOptions: [],
        currentTab: 0
      }
    },
    watch: {
      '$route.query.id': {
        immediate: true,
        handler(newVal) {
          this.aboutOptions.forEach((item, index) => {
            if (item.id === newVal) this.currentTab = index
          })
        }
      }
    },
    mounted() {
      emeiApi.getAboutList().then(({ result }) => {
        this.aboutOptions = result.map(item => ({
          label: item.title,
          id: item.id,
          content: item.content
        })).filter(item => item.id !== '6')
        const id = this.$route.query.id
        if (!id) this.currentTab = 0
        else this.aboutOptions.forEach((item, index) => {
          if (item.id === id) this.currentTab = index
        })
      })
    },
    methods: {
      handleChangeTab(index) {
        this.currentTab = index
      }
    }
  }
</script>

<style scoped lang="scss">
  .about-page {
    padding-bottom: 70px;
    .safe-area-banner {
      background: url("~@/assets/images/about_banner.jpg") center;
      .desc {
        margin-top: 30px;
        color: rgba(white, 0.8);
      }
    }
    .content-wrapper {
      margin-top: 20px;
      .title {
        width: 100%;
        height: 60px;
        line-height: 60px;
        box-shadow: 0 -2px 0 0 $color-primary inset;
      }
      .left-wrapper {
        width: 234px;
        height: 582px;
        padding: 0 20px 30px;
        background-color: white;
        .title {
          width: 88px;
        }
        .tab-wrapper {
          .tab-item {
            height: 45px;
            background-color: $color-bg-light;
            margin-top: 16px;
            cursor: pointer;
            border-radius: 2px;
            &:hover, &.is-active {
              background-color: $color-primary;
              color: white;
            }
            &:first-of-type {
              margin-top: 20px;
            }
          }
        }
      }
      .right-wrapper {
        width: 946px;
        background-color: white;
        .title {
          box-shadow: 0 -1px 0 0 $color-border inset;
          padding: 0 30px;
          color: $font-color-2;
        }
        .content {
          padding: 20px 30px;
        }
      }
    }
  }
</style>
